﻿module VideoCall4Version

open System
open Agora4Version
open Agora4Version.RTC
open Browser.Types
open Components
open Fable.Import
open Feliz.Bulma
open Feliz.Styles
open Recording
open Rtm
open Shared.Case
open Feliz
open Feliz.UseElmish
open Browser.Dom
open Fable.Core.JsInterop
open Shared.VideoCall

let t = Localization.ns("videoCall")

module Types =
    type ConnectionStatus = Online | Offline
    type Resolution = {
        Width: float
        Height: float
        AspectRatio: float
    }
    with static member Default: Resolution = { Width = 640.; Height = 320.; AspectRatio = 1. }
         static member Zero: Resolution = { Width = 0.; Height = 0.; AspectRatio = 1. }

    type RemoteUser =
        | Offline
        | Left
        | Online of IAgoraRTCRemoteUser * Resolution

    type CallContext = {
        CaseId: Guid
        DeviceId: string option
    }

    type CallContextWithMedia =
        | WithPushNotification of Guid * DeviceId * SelectMedia4Version.SelectedMedia
        | WithSharedLink of Guid * SelectMedia4Version.SelectedMedia

        with
            member this.CaseId =
                match this with
                | WithPushNotification (caseId, _, _) -> caseId
                | WithSharedLink (caseId, _) -> caseId

            member this.SelectedMedia =
                match this with
                | WithPushNotification (_, _, x) -> x
                | WithSharedLink (_, x) -> x

            member this.DeviceId =
                match this with
                | WithPushNotification (_, x, _) -> Some x
                | WithSharedLink _ -> None

    type Call = {
        Id: Guid
        Start: DateTimeOffset
        End: DateTimeOffset
    }

    type SendLinkForm = {
        InputField: string
        FormValidation: bool
        FormErrors: Map<string, string list>
        SendingSharedLink: Deferred<Result<unit, string>>
    }
        with static member empty = {InputField = ""; FormValidation = false; FormErrors = Map.empty; SendingSharedLink = HasNotStartedYet}

    type SendingLinkContext = {
        EmailForm: SendLinkForm
        PhoneForm: SendLinkForm
    }

    type Join = {
        Client: Agora4Version.RTC.Client
        ChannelId: ChannelId
        ConnectionStatus: ConnectionStatus
        ScreenSharing: ILocalVideoTrack option
        LocalUser: IAgoraRTCLocalUser
        RemoteUser: RemoteUser
        Call: Deferred<Result<Call, string>>
        Chat: Chat
        ConnectContext: ConnectContext
        CallContextWithMedia: CallContextWithMedia
        SendingLinkContext: SendingLinkContext
    }

    type CallEndReason =
        | CallRejected
        | CallerStopCall

    type InitState = {
        Client: Agora4Version.RTC.Client
        ConnectContext: ConnectContext
    }

    type SelectMedia = {
        CallContext: CallContext
    }

    type WarningMessage = string

    type State =
        | SelectMedia of SelectMedia
        | Init of Deferred<Result<InitState, string>>
        | Joined of Deferred<Result<Join, string>>
        | CallEnded of Result<TimeSpan option * CallEndReason, string>

    type UserType =
        | LocalUser
        | RemoteUser of IAgoraRTCRemoteUser
    type Msg =
        | InitClient of CallContextWithMedia * AsyncOperationStatus<Result<Client * ConnectContext, string>>
        | Join of CallContextWithMedia * AsyncOperationStatus<Result<Client * IAgoraRTCLocalUser * ChannelId * ConnectContext * WarningMessage option, string>>
        | StartCall of AsyncOperationStatus<Result<Call, string>>
        | EmailChanged of string
        | PhoneChanged of string
        | CopiedToClipboard of bool
        | SendEmailWithLink of {|Link: string; Email: string|} * AsyncOperationStatus<Result<unit, string>>
        | SendSmsWithLink of {|Link: string; Phone: string|} * AsyncOperationStatus<Result<unit, string>>
        | OnStreamSubscribed of IAgoraRTCRemoteUser
        | OnUserLeft of IAgoraRTCRemoteUser * string
        | OnReconnectStart of UID
        | OnReconnectEnd of UserType
        | MuteAudio
        | UnmuteAudio
        | MuteVideo
        | UnmuteVideo
        | ShareScreen of AsyncOperationStatus<Result<Join, string>> * (bool -> unit)
        | StopShareScreen of AsyncOperationStatus<Result<Join, string>> * (bool -> unit)
        | Leave of CallEndReason * AsyncOperationStatus<Result<TimeSpan option, string>>
        | Close
        | GoToDetails of string
        | GetStats of AsyncOperationStatus<RemoteVideoTrackStats * RTC.MediaTrackSettings>
        | OnMessageReceived of string

    [<AutoOpen>]
    module Utils =
        let isOnline = function
            | Offline
            | Left -> false
            | Online _ -> true

        let (|RemoteUserOnline|_|) (state: State) =
            match state with
            | Joined (Resolved(Ok x)) ->
                match x.RemoteUser with
                | Online (stream, resolution) -> Some (x, stream, resolution)
                | _ -> None
            | _ -> None

        let (|OngoingCall|_|) (state: State) =
            match state with
            | Joined (Resolved (Ok x)) ->
                match x.Call with
                | Resolved (Ok call) -> Some (x, call)
                | _ -> None
            | _ -> None

        let toResolution (stats: RemoteVideoTrackStats, settings: RTC.MediaTrackSettings) =
            { Width = stats.receiveResolutionWidth
              Height = stats.receiveResolutionHeight
              AspectRatio = settings.aspectRatio }

        let toVideoCallCtx (x: Join): Context  =
            { CaseId = x.CallContextWithMedia.CaseId
              ChannelId = x.ChannelId
              AgentUID = x.LocalUser.id
              GpsCoordinates = None }

        let getCall (x: Join) =
            x.Call |> DeferredResult.fold (fun call -> call |> Some) (fun _ -> None) (fun _ -> None)

        let getStartTime (x: Join) =
            x |> getCall |> Option.map (fun call -> call.Start)

        let callDurationFromNow (x: Join) =
            x |> getCall |> Option.map (fun call -> DateTimeOffset.Now - call.Start)

open Types

module Communication =
    open Fable.Remoting.Client
    open Shared

    let finishCall (call: Call) : Async<unit> = async {
        try
            let args: FinishCall = {
                CallId = call.Id
                Timestamp = DateTimeOffset.Now
            }
            return! Communication.caseApi().finishCall args |> Remoting.handleNonAuth |> Async.Ignore
        with
            ex ->
                console.error ex
    }

    let startCall caseId channelId deviceId : Async<Msg> = async {
        try
            let deviceId = match deviceId with | Some id -> id | None -> ""
            let args: Case.StartCall = {
                CallId = Guid.NewGuid()
                CaseId = caseId
                ChannelId = channelId
                DeviceId = deviceId
                Timestamp = DateTimeOffset.Now
            }
            let! result = Communication.caseApi().startCall args |> Remoting.handleNonAuth
            let x = result
                    |> Result.map (fun _ -> { Id = args.CallId; Start = args.Timestamp; End = args.Timestamp })
                    |> Result.mapError Components.Common.TranslatedErrors.ServerError.explainTranslation
            return StartCall (Finished x)
        with
            ex -> return StartCall (Finished (Error ex.Message))
    }

    let callClient (ctx: CallContextWithMedia) channelId = async {
        match ctx with
        | WithPushNotification (caseId, deviceId, _) ->
            let callClient = { CaseId = caseId; ChannelId = channelId; DeviceId = deviceId  }
            let! result = Communication.caseApi().callClient callClient |> Remoting.handleNonAuth
            return result |> Result.mapError Components.Common.TranslatedErrors.ServerError.explainTranslation
        | WithSharedLink _ -> return Ok ()
    }
    let sendLink (info: {|Link: string; Email: string|}) (x: Join) = async {
        try
            let args =
                { Link = info.Link
                  Email = info.Email
                  CaseId = x.CallContextWithMedia.CaseId }
            let! result = Communication.caseApi().sendEmailWithLink args |> Remoting.handleNonAuth
            let x = result
                        |> Result.map (fun _ -> ())
                        |> Result.mapError Components.Common.TranslatedErrors.ServerError.explainTranslation
            return SendEmailWithLink (info, (Finished x))
        with
            ex -> return SendEmailWithLink (info, Finished (Error ex.Message))
    }
    let sendLinkViaSms (info: {|Link: string; Phone: string|}) (_: Join) = async {
        try
            let args : SendingSms.SmsLinkInfo =
                { Link = info.Link
                  Phone = info.Phone}
            let! result = Communication.sendingSmsApi().sendSmsWithVideoCallLink args |> Remoting.handleNonAuth
            let x = result
                        |> Result.map (fun _ -> ())
                        |> Result.mapError Components.Common.TranslatedErrors.ServerError.explainTranslation
            return SendSmsWithLink (info, (Finished x))
        with
            ex -> return SendSmsWithLink (info, Finished (Error ex.Message))
    }

module Cmd =
    open Elmish
    open Fable.Core

    let onUserPublished (agoraEngine: Client) dispatch =
        agoraEngine.onUserPublished(fun user mediaType ->
            promise {
                let! _ = agoraEngine.subscribe(user, mediaType)
                let isVideoTrackNotNull = isNull (box user.videoTrack) |> not
                let isAudioTrackNotNull = isNull (box user.audioTrack) |> not
                if isVideoTrackNotNull && isAudioTrackNotNull then
                    dispatch (OnStreamSubscribed user)
            } )

    let onUserLeft (agoraEngine: Client) dispatch =
        agoraEngine.onUserLeft(fun user reason ->
            promise { dispatch (OnUserLeft (user, reason)) } )

    let onMediaReconnectEnd (agoraEngine: Client) localUser (onReconnectEnd: UserType -> unit) =
        agoraEngine.onMediaReconnectEnd(fun userId ->
            if string userId = localUser.id then
                onReconnectEnd(LocalUser)
            else
                let remoteUsers = agoraEngine.remoteUsers
                let user = remoteUsers |> Array.find(fun u -> u.uid = userId)
                onReconnectEnd (RemoteUser user)
        )
    let createClient (ctx: CallContextWithMedia) =
        async {
            try
                let! context = Communication.caseApi().connectCallContext () |> Remoting.handleNonAuth
                let agoraEngine = sdk.createClient Codec.H264 Mode.Rtc
                do sdk.setArea({ areaCode = "EUROPE" })
                return InitClient (ctx, Finished (Ok (agoraEngine, context)))
            with
                ex -> return InitClient (ctx, Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

    let join (ctx: CallContextWithMedia) (state: InitState) =
        async {
            try
                let channelId = Guid.NewGuid().ToString()
                //let channelId = "1024"
                if state.ConnectContext.UseProxy then
                    state.Client.startProxyServerWithTCP()
#if DEBUG
                state.Client.onIsUsingCloudProxy(fun isUsingProxy ->
                    if isUsingProxy then console.debug("Cloud proxy service activated")
                    else console.debug("Proxy service failed"))
#endif
                let! id = state.Client.join(state.ConnectContext.ApplicationId, channelId) |> Async.AwaitPromise
                let! localAudioTrack = sdk.createMicrophoneAudioTrack() |> Async.AwaitPromise
                let! localVideoTrack = sdk.createCameraVideoTrack() |> Async.AwaitPromise
                //do! localVideoTrack.setEncoderConfiguration("1080p_2") |> Async.AwaitPromise
                do! state.Client.publish([|localAudioTrack; localVideoTrack|]) |> Async.AwaitPromise

                localAudioTrack.setDevice (ctx.SelectedMedia.Mic |> Option.map (fun x -> x.deviceId) |> Option.defaultValue "")
                localVideoTrack.setDevice (ctx.SelectedMedia.Camera |> Option.map (fun x -> x.deviceId) |> Option.defaultValue "")

                let! result = Communication.callClient ctx channelId
                let localUser = {
                    id = id
                    hasAudio= localAudioTrack.enabled
                    hasVideo = localVideoTrack.enabled
                    audioTrack = localAudioTrack
                    videoTrack = localVideoTrack
                }

                let result = result |> Result.map (fun () -> (state.Client, localUser, channelId, state.ConnectContext, None))
                return Join (ctx, Finished result)
            with
                ex ->
                    return Join (ctx, Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

    let shareScreen (state: Join) toggleShareScreen =
        async {
            try
                // Create a screen track for screen sharing.
                let! screenTrack = sdk.createScreenVideoTrack() |> Async.AwaitPromise
                 // Stop playing the local video track.
                state.LocalUser.videoTrack.stop()
                // Unpublish the local video track.
                do! state.Client.unpublish([|state.LocalUser.videoTrack|]) |> Async.AwaitPromise
                // Publish the screen track.
                do! state.Client.publish([|screenTrack|]) |> Async.AwaitPromise

                let result = {state with ScreenSharing = Some screenTrack}
                state.Chat.SendMessage("START_SCREEN_SHARING")
                return ShareScreen (Finished (Ok result), toggleShareScreen)
            with
                | ex -> return ShareScreen (Finished (Error ex.Message), toggleShareScreen)
        }
        |> Cmd.fromAsync

    let stopScreenSharing (state: Join) toggleShareScreen =
        async {
            try
                let screenTrack =
                    match state.ScreenSharing with
                    | Some s -> s
                    | None -> failwith "No screen sharing"
                // Stop playing the screen track.
                screenTrack.stop()

                // Un-publish the screen track.
                do! state.Client.unpublish([|screenTrack|]) |> Async.AwaitPromise

                // Close the screen sharing track, so that floating browser toolbar will become hidden.
                screenTrack.close()

                // Publish the local video track.
                do! state.Client.publish([|state.LocalUser.videoTrack|]) |> Async.AwaitPromise

                let result = {state with ScreenSharing = None}
                state.Chat.SendMessage("STOP_SCREEN_SHARING")
                return StopShareScreen (Finished (Ok result), toggleShareScreen)
            with
                | ex -> return StopShareScreen (Finished (Error ex.Message), toggleShareScreen)
        }
        |> Cmd.fromAsync

    let leave (state: Join) reason =
        async {
            try
                if state.LocalUser.hasAudio then state.LocalUser.audioTrack.close()
                if state.LocalUser.hasVideo then state.LocalUser.videoTrack.close()
                do! state.Client.leave() |> Async.AwaitPromise
                if state.ConnectContext.UseProxy then
                    state.Client.stopProxyServer()
                return Leave (reason, Finished (Ok (state |> callDurationFromNow)))
            with
                ex -> return Leave (reason, Finished (Error ex.Message))
        }
        |> Cmd.fromAsync

    let getStats (remoteUser: IAgoraRTCRemoteUser) =
        async {
            do! Async.Sleep 1000
            let stats = remoteUser.videoTrack.getStats()
            let settings = remoteUser.videoTrack.getMediaStreamTrack().getSettings()
            return GetStats (Finished (stats, settings))
        }
        |> Cmd.fromAsync

module State =
    open Elmish
    open Extensions.View

    let init (ctx: CallContext) =
        SelectMedia { CallContext = ctx }, Cmd.none

    let update (msg: Msg) (state: State)  =
        match msg, state with
        | InitClient (callContextWithMedia, Started), _ ->
            Init InProgress, Cmd.createClient callContextWithMedia
        | InitClient (caseId, Finished (Ok (client, ctx))), Init InProgress ->
            Init (Resolved (Ok { Client = client; ConnectContext = ctx })), Cmd.ofMsg (Join (caseId, Started))
        | InitClient (_, Finished (Error x)), Init InProgress ->
            Init (Resolved (Error x)), Cmd.none
        | Join (caseId, Started), Init (Resolved (Ok x)) ->
            Joined InProgress, Cmd.join caseId x
        | Join (ctx, Finished (Ok (client, localUser, channelId, connectContext, warningMessage))), Joined InProgress ->
            let chat = new Chat(channelId)
            let s = { Client = client
                      LocalUser = localUser; ChannelId = channelId
                      ConnectionStatus = ConnectionStatus.Online
                      ScreenSharing = None
                      RemoteUser = RemoteUser.Offline
                      Chat = chat
                      Call = HasNotStartedYet
                      ConnectContext = connectContext
                      CallContextWithMedia = ctx
                      SendingLinkContext = {
                          EmailForm = SendLinkForm.empty
                          PhoneForm = SendLinkForm.empty } }
            let onReconnectStart dispatch = client.onMediaReconnectStart(fun userId -> dispatch (OnReconnectStart userId))
            let onReconnectEnd dispatch = Cmd.onMediaReconnectEnd client localUser (fun x -> dispatch (OnReconnectEnd x))
            let onMessage dispatch = chat.OnMessage.Add (OnMessageReceived >> dispatch)
            Joined (Resolved (Ok s)), Cmd.batch [
                Cmd.ofSub (Cmd.onUserPublished client)
                Cmd.ofSub (Cmd.onUserLeft client)
                Cmd.ofSub onReconnectStart
                Cmd.ofSub onReconnectEnd
                Cmd.ofSub onMessage
                warningMessage |> Option.map Cmd.toastWarning |> Option.defaultValue Cmd.none
            ]
        | Join (_, Finished (Error x)), Joined InProgress ->
            Joined (Resolved (Error x)), Cmd.none
        | Leave (reason, Started), Joined (Resolved (Ok x)) ->
            x.Call |> DeferredResult.tee (fun call -> Cmd.OfAsync.start (Communication.finishCall call)) |> ignore
            state, Cmd.leave x reason
        | Leave (reason, Finished x), Joined (Resolved (Ok _)) ->
            let payload = x |> Result.map (fun ce -> ce, reason)
            CallEnded payload, Cmd.none
        | Close, SelectMedia _
        | Close, CallEnded _ ->
            window.close()
            state, Cmd.none
        | GoToDetails id, CallEnded _ -> state, Router.navigateTo (Router.CaseDetails id)
        | OnStreamSubscribed remoteUser, Joined (Resolved (Ok x)) ->
            let newState = { x with RemoteUser = RemoteUser.Online (remoteUser, Resolution.Default) }
            let startCallCmd = if x.Call |> Deferred.notStartedYet then Cmd.ofMsg (StartCall Started) else Cmd.none
            Joined (Resolved (Ok newState)), Cmd.batch [Cmd.ofMsg (GetStats Started); startCallCmd]
        | OnStreamSubscribed _, Joined InProgress ->
            state, Cmd.none
        | StartCall Started, Joined (Resolved (Ok x)) ->
            Joined (Resolved (Ok { x with Call = InProgress })),
            Cmd.fromAsync (Communication.startCall x.CallContextWithMedia.CaseId x.ChannelId x.CallContextWithMedia.DeviceId)
        | StartCall (Finished call), Joined (Resolved (Ok x)) ->
            Joined (Resolved (Ok { x with Call = Resolved call })), Cmd.none
        | GetStats Started, RemoteUserOnline (_, remoteUser, _) ->
            state, Cmd.getStats remoteUser
        | GetStats (Finished stats), RemoteUserOnline (joinState, stream, resolution) ->
            let newResolution = stats |> toResolution
            let newState =
                if newResolution <> resolution && newResolution <> Resolution.Zero then
                    let newState = { joinState with RemoteUser = RemoteUser.Online (stream, newResolution) }
                    Joined (Resolved (Ok newState))
                else
                    state
            newState, Cmd.ofMsg (GetStats Started)
        | OnUserLeft (user, reason), Joined (Resolved (Ok x)) ->
            let newState = { x with RemoteUser = RemoteUser.Offline }
            Joined (Resolved (Ok newState)), Cmd.none
        | OnReconnectStart uId, Joined (Resolved (Ok x)) ->
            let newState =
                if uId = x.LocalUser.id then { x with ConnectionStatus = ConnectionStatus.Offline }
                else { x with RemoteUser = RemoteUser.Offline }
            Joined (Resolved (Ok newState)), Cmd.none
        | OnReconnectEnd userType, Joined (Resolved (Ok x)) ->
            let newState =
                match userType with
                | LocalUser -> { x with ConnectionStatus = ConnectionStatus.Online }
                | RemoteUser remoteUser -> { x with RemoteUser = RemoteUser.Online (remoteUser, Resolution.Default) }
            Joined (Resolved (Ok newState)), Cmd.none
        | CopiedToClipboard t, Joined (Resolved (Ok _)) ->
            let cmd = if t then Cmd.toastSuccess "Copied" else Cmd.toastError "Error by copying"
            state, cmd
        | EmailChanged email, Joined (Resolved (Ok x)) ->
            let errors = Common.Form.validationEmail email x.SendingLinkContext.EmailForm.FormValidation
            let newState = { x with SendingLinkContext =
                                        {x.SendingLinkContext with EmailForm = { x.SendingLinkContext.EmailForm with InputField = email; FormErrors = errors.Value}}}
            Joined (Resolved (Ok newState)), Cmd.none
        | PhoneChanged phone, Joined (Resolved (Ok x)) ->
            let phone = sprintf "+46%s" phone
            let errors = Common.Form.validationErrors phone x.SendingLinkContext.PhoneForm.FormValidation
            let newState = { x with SendingLinkContext =
                                    {x.SendingLinkContext with PhoneForm = { x.SendingLinkContext.PhoneForm with InputField = phone; FormErrors = errors.Value}}}
            Joined (Resolved (Ok newState)), Cmd.none
        | SendEmailWithLink (info, Started), Joined (Resolved (Ok x)) ->
            let newState = { x with SendingLinkContext = {x.SendingLinkContext with EmailForm = {x.SendingLinkContext.EmailForm with FormValidation = true}}}
            let errors = Common.Form.validationEmail x.SendingLinkContext.EmailForm.InputField newState.SendingLinkContext.EmailForm.FormValidation
            if errors.Value = Map.empty then
                let newState = { x with SendingLinkContext = {x.SendingLinkContext with EmailForm = { x.SendingLinkContext.EmailForm with SendingSharedLink = InProgress; FormErrors = errors.Value; FormValidation = true }}}
                Joined (Resolved (Ok newState)), Cmd.fromAsync (Communication.sendLink info x)
            else
                let newState = { x with SendingLinkContext = {x.SendingLinkContext with EmailForm = { x.SendingLinkContext.EmailForm with SendingSharedLink = HasNotStartedYet; FormErrors = errors.Value; FormValidation = true }}}
                Joined (Resolved (Ok newState)), Cmd.none
        | SendEmailWithLink (_, Finished res), Joined (Resolved (Ok x)) ->
            let newState = { x with SendingLinkContext = {x.SendingLinkContext with EmailForm = { x.SendingLinkContext.EmailForm with SendingSharedLink = Resolved res }}}
            Joined (Resolved (Ok newState)), Cmd.none
        | SendSmsWithLink (info, Started), Joined (Resolved (Ok x)) ->
            let newState = { x with SendingLinkContext = {x.SendingLinkContext with PhoneForm = {x.SendingLinkContext.PhoneForm with FormValidation = true}}}
            let errors = Common.Form.validationErrors x.SendingLinkContext.PhoneForm.InputField newState.SendingLinkContext.PhoneForm.FormValidation
            if errors.Value = Map.empty then
                let newState = { x with SendingLinkContext = {x.SendingLinkContext with PhoneForm = { x.SendingLinkContext.PhoneForm with SendingSharedLink = InProgress; FormErrors = errors.Value; FormValidation = true }}}
                Joined (Resolved (Ok newState)), Cmd.fromAsync (Communication.sendLinkViaSms info x)
            else
                let newState = { x with SendingLinkContext = {x.SendingLinkContext with PhoneForm = { x.SendingLinkContext.PhoneForm with SendingSharedLink = HasNotStartedYet; FormErrors = errors.Value; FormValidation = true }}}
                Joined (Resolved (Ok newState)), Cmd.none
        | SendSmsWithLink (_, Finished res), Joined (Resolved (Ok x)) ->
            let newState = { x with SendingLinkContext = {x.SendingLinkContext with PhoneForm = { x.SendingLinkContext.PhoneForm with SendingSharedLink = Resolved res }}}
            Joined (Resolved (Ok newState)), Cmd.none
        | MuteAudio, Joined (Resolved (Ok x)) ->
            x.LocalUser.audioTrack.setMuted(true)
            state, Cmd.none
        | UnmuteAudio, Joined (Resolved (Ok x)) ->
            x.LocalUser.audioTrack.setMuted(false)
            state, Cmd.none
        | MuteVideo, Joined (Resolved (Ok x)) ->
            x.LocalUser.videoTrack.setMuted(true)
            state, Cmd.none
        | UnmuteVideo, Joined (Resolved (Ok x)) ->
            x.LocalUser.videoTrack.setMuted(false)
            state, Cmd.none
        | ShareScreen (Started, toggleShareScreen), Joined (Resolved (Ok x)) ->
            state, Cmd.shareScreen x toggleShareScreen
        | ShareScreen (Finished newState, toggleShareScreen), Joined (Resolved (Ok x)) ->
            match newState with
            | Ok s ->
                let cmd =
                    match s.ScreenSharing with
                    | Some sharing ->
                        let onTrackEnded (dispatch: Msg -> unit) =
                            sharing.onTrackEnded (fun _ -> dispatch (StopShareScreen (Started, toggleShareScreen)))
                        Cmd.ofSub onTrackEnded
                    | None -> Cmd.none
                Joined (Resolved (Ok s)), cmd
            | Error x ->
                state, Cmd.toastError x
        | StopShareScreen (Started, toggleShareScreen), Joined (Resolved (Ok x)) ->
            state, Cmd.stopScreenSharing x toggleShareScreen
        | StopShareScreen (Finished newState, toggleShareScreen), Joined (Resolved (Ok x)) ->
            match newState with
            | Ok s ->
                toggleShareScreen(false)
                Joined (Resolved (Ok s)), Cmd.none
            | Error x ->
                state, Cmd.toastError x
        | OnMessageReceived message, Joined (Resolved (Ok _)) ->
            match message.ToUpperInvariant() with
            | "CALL_REJECTED" -> state, Cmd.ofMsg (Leave (CallRejected, Started))
            | _ ->  state, Cmd.none
        | _, _ ->
            console.warn ("The combination of state and message is not expected", state, msg)
            state, Cmd.none

module Utils =
    type Size = {
        Width: float
        Height: float
    }
    with static member Zero = { Width = 0.; Height = 0. }
         static member FromResolution (x: Resolution) = { Width = x.Width; Height = x.Height }
         static member From (x: {| Width: float; Height: float |}) = { Width = x.Width; Height = x.Height }

    let scale (n: Size) (aspect: double) =
        if Math.Abs(aspect) = 0. then Size.Zero
        elif aspect <= 1. then { Width = n.Height * aspect; Height = n.Height }
        else { Width = n.Width; Height = n.Width / aspect }

module View =
    open Hooks
    open Utils
    open Elmish

    type LocalTrackProps = {
        LocalUser: IAgoraRTCLocalUser
        ScreenSharing: ILocalVideoTrack option
        Toolbar: ReactElement
    }

    type RemoteTrackProps = {
        RemoteUser: IAgoraRTCRemoteUser
        Resolution: Resolution option
    }

    let private renderRemoteUserTrack = React.functionComponent(fun (props: RemoteTrackProps) ->
        let id = "id-" + props.RemoteUser.uid
        let play () =
            if props.RemoteUser.hasAudio then props.RemoteUser.audioTrack.play()
            if props.RemoteUser.hasVideo then props.RemoteUser.videoTrack.play(id)
        let ref, nodeSize = useSize()

        let toPixels (x: Size) = {| Width = length.px x.Width; Height = length.px x.Height |}
        let evalSizes (node: {| Width: float; Height: float |} option) (video: Resolution option) =
            Option.map2 scale (node |> Option.map Size.From) (video |> Option.map (fun x -> x.AspectRatio))
            |> Option.map toPixels
            |> Option.defaultValue {| Width = length.percent 100; Height = length.percent 100 |}

        let toStyle (x: {| Width: ICssUnit; Height: ICssUnit|}) =
            [
                style.height x.Height
                style.width x.Width
                style.margin.auto
            ]

        React.useEffect(play)
        Html.div [
            prop.classes [ AppCss.CallFull ]
            prop.ref ref
            prop.children [
                Html.div [
                    prop.style (evalSizes nodeSize props.Resolution |> toStyle)
                    prop.id id
                ]
            ]
        ]
    )

    let private renderLocalUser = React.functionComponent(fun (props: LocalTrackProps) ->
        let id = "id-" + props.LocalUser.id
        let play () =
            match props.ScreenSharing with
            | Some track -> track.play(id)
            | None ->
                if props.LocalUser.hasVideo then props.LocalUser.videoTrack.play(id)
        React.useEffect(play, [| box props.ScreenSharing |])
        Html.div [
            prop.id id
            prop.classes [ AppCss.CallSmallWindow ]
            prop.children [
                Html.div [
                    prop.classes [ AppCss.StreamToolbar ]
                    prop.children [ props.Toolbar ]
                ]
            ]
        ]
    )

    type NavbarProps = {
        ChannelId: string
        StartTime: DateTimeOffset option
        onLeave: unit -> unit
    }
    let private navbar = React.functionComponent("Navbar", fun (props: NavbarProps) ->
        let logo : string = importAll "./public/img/logo-white.svg"
        Bulma.navbar [
            color.isPrimary
            navbar.isTransparent
            text.isUppercase
            prop.classes [ BulmaCss.``is-fixed-top`` ]
            prop.children [
                Bulma.container [
                    Bulma.navbarBrand.div [
                        Bulma.navbarItem.a [
                            Html.img [ prop.src logo ]
                        ]
                    ]
                    Bulma.navbarMenu [
                        Bulma.navbarStart.div [
                            Bulma.navbarItem.div []
                        ]
                        Bulma.navbarEnd.div [
                            Bulma.navbarItem.div [
                                match props.StartTime with
                                | Some startTime ->  Timer.reactComponent { Start = startTime }
                                | None -> Html.none
                            ]
                            Bulma.navbarItem.div [
                                prop.children [
                                    Bulma.button.button [
                                        Bulma.color.isDanger
                                        prop.text (t "call.button.leave")
                                        prop.onClick (fun x -> x.preventDefault(); props.onLeave())
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    )

    let renderRemote = function
        | Offline -> Loader.medium (t "call.waiting.of.joining")
        | Left -> Html.text "Remote user left the call"
        | Online (remoteUser, resolution) ->
            renderRemoteUserTrack {
                   RemoteUser = remoteUser
                   Resolution = Some resolution }

    type TrackToolbarProps = {
        muteAudio: unit -> unit
        unmuteAudio: unit -> unit
        muteVideo: unit -> unit
        unmuteVideo: unit -> unit
        shareScreen: (bool -> unit) -> unit
        stopScreenSharing: (bool -> unit) -> unit
    }

    let private trackToolbar = React.functionComponent(fun (props: TrackToolbarProps) ->
        let audioMuted, setAudioState = React.useState(false)
        let videoMuted, setVideoState = React.useState(false)
        let screenSharing, setScreenSharingState = React.useState(false)

        let toggleAudio() =
            if audioMuted then props.unmuteAudio() else props.muteAudio()
            setAudioState (not audioMuted)
        let toggleVideo() =
            if videoMuted then props.unmuteVideo() else props.muteVideo()
            setVideoState (not videoMuted)
        let toggleScreenSharing() =
            if screenSharing then props.stopScreenSharing(setScreenSharingState)
            else props.shareScreen(setScreenSharingState)
            setScreenSharingState(not screenSharing)

        Bulma.buttons [
            Bulma.buttons.isCentered
            prop.children [
                Bulma.button.button [
                    Bulma.button.isRounded
                    Bulma.button.isOutlined
                    prop.onClick (fun x -> x.preventDefault(); toggleAudio())
                    prop.children [
                        Bulma.icon [
                            Html.i [
                                prop.className [
                                    MdiCss.Mdi; MdiCss.Mdi24Px;
                                    if audioMuted then MdiCss.MdiMicrophoneOff else MdiCss.MdiMicrophone
                                ]
                            ]
                        ]
                    ]
                ]
                Bulma.button.button [
                    Bulma.button.isRounded
                    Bulma.button.isInverted
                    prop.onClick (fun x -> x.preventDefault(); toggleVideo())
                    prop.children [
                        Bulma.icon [
                            Html.i [
                                prop.className [
                                    MdiCss.Mdi; MdiCss.Mdi24Px;
                                    if videoMuted then MdiCss.MdiVideoOff else MdiCss.MdiVideo
                                ]
                            ]
                        ]
                    ]
                ]
                Bulma.button.button [
                    Bulma.button.isRounded
                    if screenSharing then color.isPrimary
                    prop.onClick (fun x -> x.preventDefault(); toggleScreenSharing())
                    prop.children [
                        Bulma.icon [
                            Html.i [
                                prop.className [ MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiMonitorShare ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    )

    let renderSendLinkBox (state: Join, onEmailChange: string -> unit, onPhoneChange: string -> unit, sendEmailWithLink, sendSmsWithLink, onClipboardCopy) =

        let baseUrl = window.location.origin
        let link = Uri(baseUrl |> Uri, sprintf "joincall/%A" state.ChannelId).ToString()

        Bulma.columns [
            Bulma.column [
                Bulma.box [
                    prop.className AppCss.ShareLinkWindow
                    prop.children [
                        Html.fieldSet [
                            prop.children [
                                Bulma.field.div [
                                    field.hasAddons
                                    prop.children [
                                        Bulma.control.p [
                                            control.isExpanded
                                            prop.children [
                                                Bulma.input.text [
                                                    prop.id "myInput"
                                                    prop.value link
                                                    prop.readOnly true
                                                ]
                                            ]
                                        ]
                                        Bulma.control.p [
                                            Bulma.button.a[
                                                prop.onClick (fun x ->
                                                    let copyText = document.getElementById("myInput") :?> HTMLInputElement
                                                    copyText.select()
                                                    copyText.setSelectionRange(0, 99999)
                                                    let t = document.execCommand("copy")
                                                    x.preventDefault()
                                                    onClipboardCopy t
                                                    )
                                                prop.children [
                                                    Bulma.icon [
                                                        Html.i [
                                                            prop.classes [MdiCss.Mdi; MdiCss.Mdi24Px; MdiCss.MdiContentCopy]
                                                        ]
                                                    ]
                                                ]
                                            ]
                                        ]
                                    ]
                                ]
                                Bulma.field.div [
                                    field.isGrouped
                                    prop.style [style.marginBottom 0]
                                    prop.children [
                                        Bulma.control.p [
                                            prop.disabled (state.SendingLinkContext.EmailForm.SendingSharedLink = InProgress)
                                            control.isExpanded
                                            prop.children [
                                                Bulma.input.text [
                                                    prop.disabled (state.SendingLinkContext.EmailForm.SendingSharedLink = InProgress)
                                                    prop.style [style.backgroundColor.white]
                                                    prop.placeholder(t "title.email")
                                                    prop.onChange onEmailChange
                                                    prop.defaultValue state.SendingLinkContext.EmailForm.InputField
                                                ]
                                            ]
                                        ]
                                        Bulma.control.p [
                                            Bulma.button.button [
                                                prop.style [style.alignSelf.flexEnd]
                                                if state.SendingLinkContext.EmailForm.InputField = "" then prop.disabled true
                                                if state.SendingLinkContext.EmailForm.SendingSharedLink = InProgress then button.isLoading
                                                color.isPrimary
                                                prop.text (t "call.button.send.link")
                                                prop.onClick (fun e -> e.preventDefault(); sendEmailWithLink {| Link = link; Email = state.SendingLinkContext.EmailForm.InputField |})
                                            ]
                                        ]
                                    ]
                                ]
                                let helpInfo =
                                    let errors =
                                        match state.SendingLinkContext.EmailForm.SendingSharedLink with
                                        | Resolved (Error er) ->
                                            [sprintf "%s%s" (t "error") er]
                                        | _ ->
                                            Common.Form.getFieldError state.SendingLinkContext.EmailForm.FormErrors "Email"
                                    match state.SendingLinkContext.EmailForm.SendingSharedLink with
                                    | Resolved (Ok _) ->
                                        Bulma.help [
                                            color.isPrimary
                                            prop.text (t "call.sending.link.success")
                                        ]
                                    | _ ->
                                        Bulma.help [
                                            prop.style [style.color.red]
                                            prop.text (errors |> List.fold (fun s x -> sprintf "%s %s" s x) String.Empty)
                                        ]
                                Html.div [
                                    prop.style [style.paddingBottom 12]
                                    prop.children [helpInfo]
                                ]

                                let helpInfo =
                                    let errors =
                                        match state.SendingLinkContext.PhoneForm.SendingSharedLink with
                                        | Resolved (Error er) ->
                                            [sprintf "%s%s" (t "error") er]
                                        | _ ->
                                            Common.Form.getFieldError state.SendingLinkContext.PhoneForm.FormErrors "Phone"
                                    match state.SendingLinkContext.PhoneForm.SendingSharedLink with
                                    | Resolved (Ok _) ->
                                        Bulma.help [
                                            color.isPrimary
                                            prop.text (t "call.sending.link.success")
                                        ]
                                    | _ ->
                                        Bulma.help [
                                            prop.style [style.color.red]
                                            prop.text (errors |> List.fold (fun s x -> sprintf "%s %s" s x) String.Empty)
                                        ]
                                Bulma.field.div [
                                    field.isGrouped
                                    prop.style [style.marginBottom 0]
                                    prop.children [
//                                        Bulma.control.p [
//                                            Bulma.control.div [
//                                                Bulma.input.text [
//                                                    prop.disabled (state.SendingLinkContext.PhoneForm.SendingSharedLink = InProgress)
//                                                    prop.style [style.backgroundColor.white; style.marginBottom (length.rem 0.5)]
//                                                    prop.placeholder(t "title.phone")
//                                                    prop.onChange onPhoneChange
//                                                    prop.defaultValue state.SendingLinkContext.PhoneForm.InputField
//                                                ]
//                                            ]
//                                        ]
                                        Bulma.control.p [
                                            control.isExpanded
                                            prop.children [
                                                Bulma.field.div [
                                                    field.hasAddons
                                                    prop.children [
                                                        Bulma.control.p [
                                                            prop.children [
                                                                Bulma.button.span [
                                                                    button.isStatic
                                                                    prop.children [
                                                                        Bulma.icon [
                                                                            prop.className [MdiCss.Mdi; MdiCss.Mdi18Px; MdiCss.MdiPhone;]
                                                                        ]
                                                                        Bulma.text.span "+46"
                                                                    ]
                                                                ]

                                                            ]
                                                        ]
                                                        Bulma.control.p [
                                                            prop.style [style.width (length.percent 100)]
                                                            prop.children [
                                                                Bulma.input.text [
                                                                    prop.disabled (state.SendingLinkContext.PhoneForm.SendingSharedLink = InProgress)
                                                                    prop.style [style.backgroundColor.white]
                                                                    prop.placeholder (t "title.phone")
                                                                    prop.defaultValue state.SendingLinkContext.PhoneForm.InputField
                                                                    prop.onChange onPhoneChange
                                                                ]
                                                            ]
                                                        ]
                                                    ]
                                                ]
                                            ]
                                        ]
                                        Bulma.control.p [
                                            Bulma.button.button [
                                                prop.style [style.alignSelf.flexEnd]
                                                if state.SendingLinkContext.PhoneForm.InputField = "" then prop.disabled true
                                                if state.SendingLinkContext.PhoneForm.SendingSharedLink = InProgress then button.isLoading
                                                color.isPrimary
                                                prop.text (t "call.button.send.link")
                                                prop.onClick (fun e -> e.preventDefault(); sendSmsWithLink {| Link = link; Phone = state.SendingLinkContext.PhoneForm.InputField |})
                                            ]
                                        ]
                                    ]
                                ]
                                Html.div [
                                    prop.style [style.paddingBottom 12]
                                    prop.children [helpInfo]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]

    let renderToolbar (state: Join, onEmailChange, onPhoneChange, sendEmailWithLink, sendSmsWithLink, onClipboardCopy) =
        Html.div [
            prop.className AppCss.RecordToolbar
            prop.children [
                match state.RemoteUser, state.CallContextWithMedia with
                | RemoteUser.Offline, WithSharedLink _ ->
                    renderSendLinkBox (state, onEmailChange, onPhoneChange, sendEmailWithLink, sendSmsWithLink, onClipboardCopy)
                | RemoteUser.Left, WithSharedLink _ ->
                    renderSendLinkBox (state, onEmailChange, onPhoneChange, sendEmailWithLink, sendSmsWithLink, onClipboardCopy)
                | _ -> Html.none
                Bulma.columns [
                    Bulma.column [
                        Component.recording { Context = toVideoCallCtx state; RtmChat = state.Chat }
                    ]
                    Bulma.column [
                        Photo.Component.photo { Context = toVideoCallCtx state; RtmChat = state.Chat }
                    ]
                ]
            ]
        ]

    let renderStatus (state: Join) =
        match state.ConnectionStatus with
        | ConnectionStatus.Offline ->
            Html.div [
                prop.style [
                    style.display.flex
                    style.width (length.percent 100)
                    style.justifyContent.center
                ]
                prop.classes [AppCss.ConnectionNotification]
                prop.children [
                    Bulma.notification [
                        color.isDanger
                        prop.text "Poor connection. Trying to reconnect ..."
                    ]
                ]
            ]
        | ConnectionStatus.Online ->
            Html.none

    let private logo : string = importAll "./public/img/logo-white.svg"

    let render caseId (state: State) (dispatch: Msg -> unit) =
        match state with
        | SelectMedia x ->
            let onProceedCall (media: SelectMedia4Version.SelectedMedia) =
                match x.CallContext.DeviceId with
                | Some deviceId ->
                    dispatch (InitClient (CallContextWithMedia.WithPushNotification (x.CallContext.CaseId, deviceId, media), Started))
                | None ->
                    ()
            let onProceedCallWithSharedLink (media: SelectMedia4Version.SelectedMedia) =
                dispatch (InitClient (CallContextWithMedia.WithSharedLink (x.CallContext.CaseId, media), Started))

            SelectMedia4Version.render
                {|
                   OnProceedCall = onProceedCall
                   OnProceedCallWithSharedLink = onProceedCallWithSharedLink
                   OnCancel = fun () -> dispatch Close
                   IsWithBooking = x.CallContext.DeviceId.IsSome
                |}
        | Init HasNotStartedYet
        | Init InProgress -> Loader.medium "Initialize client ..."
        | Init (Resolved (Ok _)) -> Html.span "Client initialized"
        | Init (Resolved (Error ex)) -> Html.span (sprintf "Error while initialize client: %s" ex)
        | Joined HasNotStartedYet
        | Joined InProgress -> Loader.medium (t "call.joining")
        | Joined (Resolved (Ok x)) ->
            let toolbar = trackToolbar {
                muteAudio = fun () -> dispatch MuteAudio
                unmuteAudio = fun () -> dispatch UnmuteAudio
                muteVideo = fun () -> dispatch MuteVideo
                unmuteVideo = fun () -> dispatch UnmuteVideo
                shareScreen = fun x -> dispatch (ShareScreen (Started, x))
                stopScreenSharing = fun x -> dispatch (StopShareScreen (Started, x))
            }
            let startTime = x.Call |> DeferredResult.fold (fun call -> Some call.Start) (fun _ -> None) (fun _ -> None)
            Html.div [
                navbar { ChannelId = x.ChannelId; StartTime = startTime; onLeave = fun () -> dispatch (Leave (CallerStopCall, Started)) }
                Bulma.hero [
                    hero.isFullHeightWithNavbar
                    prop.children [
                        Bulma.heroBody [
                            prop.classes [ BulmaCss.``is-paddingless`` ]
                            prop.children [
                                renderLocalUser { LocalUser = x.LocalUser; ScreenSharing = x.ScreenSharing; Toolbar = toolbar }
                                renderRemote x.RemoteUser
                                renderToolbar ( x,
                                               (fun x -> dispatch (EmailChanged x)),
                                               (fun x -> dispatch (PhoneChanged x)),
                                               (fun x -> dispatch (SendEmailWithLink (x, Started))),
                                               (fun x -> dispatch (SendSmsWithLink (x, Started))),
                                               (fun x -> dispatch (CopiedToClipboard x))
                                               )
                                renderStatus x
                            ]
                        ]
                    ]
                ]
            ]
        | Joined (Resolved (Error ex)) -> Html.span (sprintf "Error while join call: %s" ex)
        | CallEnded (Ok (duration, _)) ->
            Html.div [
                Bulma.navbar [
                    Bulma.navbar.isTransparent
                    Bulma.color.isPrimary
                    Bulma.text.isUppercase
                    prop.classes [ BulmaCss.``is-fixed-top`` ]
                    prop.children [
                        Bulma.container [
                            Bulma.navbarBrand.div [
                                Bulma.navbarItem.a [
                                    Html.img [ prop.src logo ]
                                ]
                            ]
                        ]
                    ]
                ]
                Bulma.section [
                    Bulma.container [
                        prop.children[
                            Bulma.columns[
                                text.isUppercase
                                prop.style[style.marginBottom 32]
                                prop.children[
                                    Bulma.column[
                                        Bulma.column.isNarrow
                                        prop.style[
                                            style.color.black
                                            style.fontSize 32
                                            style.fontWeight.bold
                                        ]
                                        prop.text (t "call.end")
                                    ]
                                    Bulma.column[
                                        Bulma.column.isNarrow
                                        prop.style[
                                            style.color.black
                                            style.opacity 0.25
                                            style.paddingLeft 20
                                            style.fontSize 32
                                            style.fontWeight.bold
                                        ]
                                        prop.children[Common.renderDurationOption duration]
                                    ]
                                ]
                            ]
                            Bulma.buttons[
                                Bulma.button.button [
                                    color.isBlack
                                    prop.classes [AppCss.ButtonShadow]
                                    prop.text ( t "call.closeTab" )
                                    prop.onClick (fun x -> x.preventDefault(); dispatch Close)
                                ]
                                Bulma.button.button [
                                    color.isPrimary
                                    prop.classes [AppCss.ButtonShadow]
                                    prop.text (t "call.toCaseDetails")
                                    prop.onClick (fun x -> x.preventDefault(); GoToDetails (caseId.ToString()) |> dispatch)
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        | CallEnded (Error ex) -> Html.span (sprintf "Error while end call: %s" ex)

module Component =
    open State
    open Elmish

    type Props = {
        CaseId: Guid
        DeviceId: string option
    }

    let setTabTitle title =
        document.title <- title

    let videoCall = React.functionComponent(fun (props: Props) ->
        let state, dispatch = React.useElmish(init { CaseId = props.CaseId; DeviceId = props.DeviceId }, update, [||])

        React.useEffectOnce(fun () -> setTabTitle "SBH call")

        View.render props.CaseId state dispatch
    )